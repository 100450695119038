<template>
  <div>
    <template v-if="estimates">
      <div class="contentBox">
        <div class="contentBox__head">Buget</div>
        <div class="contentBox__body">
          <div class="contentBox__body--title">
            <span>{{ estimates.budget.toFixed(2) }}</span
            >Total budget
          </div>
          <p class="contentBox__body--subTitle">
            {{ estimates.reach }} Estimated reach
          </p>

          <div class="d-flex align-items-center justify-content-between mb-15">
            <p class="heading m-0">Influncers</p>
            <span class="range">{{ selectedInfluencers }}</span>
          </div>
          <!-- Rang slider -->
          <base-slider
            :min="1"
            :max="influencersCount"
            v-model="selectedInfluencers"
            @change="getEstimatesBudget"
            :value="selectedInfluencers"
          />
          <!-- Rang slider -->
          <div class="d-flex align-items-center justify-content-between mb-15">
            <p class="heading m-0">Posts</p>
            <span class="range"> {{ campaign.posts }} </span>
          </div>

          <!-- Rang slider -->
          <base-slider
            :min="1"
            :max="filters.posts"
            v-model="campaign.posts"
            @change="getEstimates"
          />
          <!-- Rang slider -->
          <div class="button-row">
            <a
              href="javascript:void(0);"
              @click="continueSubmission"
              class="btn btn-primary full"
              >Continue</a
            >
            <!-- v-b-modal.instructions -->
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";
import cloneDeep from "lodash/cloneDeep";
export default {
  props: {
    campaign: Object,
    newCampaign: Object,
  },
  data() {
    return {
      processing: false,
      influencersCount: 0,
      selectedInfluencers: 0,
      selectedInfluencersVal: 0,
    };
  },
  computed: {
    ...mapState({
      filters: (state) => state.campaign.filters,
      estimates: (state) => state.campaign.estimates,
    }),
  },
  created() {
    this.influencersCount = this.filters.influencers;
    this.selectedInfluencers = this.filters.default.influencers;
  },
  watch: {
    estimates: {
      handler() {
        this.influencersCount = cloneDeep(this.estimates.influencers);
        this.selectedInfluencers = this.campaign.influencers;
        this.newCampaign.budget = this.estimates.budget;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    getEstimates() {
      this.campaign.influencers = this.selectedInfluencers;
      this.newCampaign.budget = this.estimates.budget;
      this.$emit("getEstimate");
    },
    getEstimatesBudget() {
      this.campaign.influencers = this.selectedInfluencers;
      this.newCampaign.budget = this.estimates.budget;
      this.$emit("getEstimate");
    },
    continueSubmission() {
      this.$emit("continue");
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  display: inline-flex;
  user-select: none;
  border: 0;
  line-height: 1;
  border-radius: 0;
  transition: all 0.5s ease;
  color: #fff;
  padding: rem(14px) rem(65px);
  border-radius: 16px;
  font-size: rem(20px);
  font-weight: 600;
  width: 270px;
  vertical-align: middle;
  position: relative;
}
</style>
